import React, {useEffect, useState, useRef} from 'react';
import {useAnimation} from 'framer-motion';
import {withTheme, DefaultTheme} from 'styled-components';
// Images
import NegativeIcon from '../../images/critical-icon-white.svg';
// Style
import S from './style';

interface ToastProps {
  text: string | React.ReactNode;
  icon: string;
  toggled: boolean;
  type: 'default' | 'negative';
  hideToast: Function;
  windowHeight: number; // using windowHeight instead of vh because mobile browser toolbar creating strange behavior
  placement?: 'top' | 'bottom';
  theme: DefaultTheme;
}

const Toast = ({
  text,
  icon,
  toggled,
  type,
  hideToast,
  windowHeight,
  placement = 'bottom',
  theme,
}: ToastProps) => {
  const toastRef = useRef<HTMLDivElement>(null);

  const controls = useAnimation();

  const [displayedText, setDisplayedText] = useState<string | React.ReactNode>(
    ''
  );
  const [displayedIcon, setDisplayedIcon] = useState('');
  const [displayedType, setDisplayedType] = useState('default');

  useEffect(() => {
    if (windowHeight) {
      controls.set({
        top: windowHeight,
        visibility: 'hidden',
      });
    }
  }, [windowHeight]);

  // Flow
  // 1. Toast has never been open // displayedText = '' text = ''
  // 2. Toast real text has changed but display text is still empty // displayedText = '' text = 'something'
  // 3. Toast display text has changed // displayedText = 'something' text = 'something'
  // ****** Toast height from new text is updated and toast pops up here ******
  // ****** Toast goes down ******
  // 4. Toast display text is same and toast real text becomes empty // displayedText = 'something' text = ''
  // 5. Toast real text has changed but display text hasn't been updated // displayedText = 'something' text = 'something else'
  // *** cycle continues from step 3
  useEffect(() => {
    if (toggled) {
      setDisplayedText(text);
      setDisplayedIcon(icon);
      setDisplayedType(type);
    } else {
      if (!windowHeight || !toastRef.current?.clientHeight) return;
      let endTop = 0;
      if (placement === 'top') {
        endTop = -toastRef.current.clientHeight;
      } else {
        endTop = windowHeight;
      }
      // must use top property instead of bottom since toast can change height
      controls.start({
        top: endTop,
        transition: {duration: 0.15, ease: 'easeInOut'},
        transitionEnd: {visibility: 'hidden'},
      });

      // clear out value in case we need same alert to go twice
      setTimeout(() => {
        setDisplayedText('');
      }, 500);
    }
  }, [toggled]);

  useEffect(() => {
    if (displayedText && displayedText === text) {
      const onScreenTime = type === 'negative' ? 7000 : 3500;
      if (toastRef?.current && windowHeight) {
        let startTop = 0;
        if (placement === 'top') {
          startTop = -toastRef.current.clientHeight;
        } else {
          startTop = windowHeight;
        }
        let endTop = 0;
        if (placement === 'top') {
          endTop = parseInt(theme.spacing.MD, 10);
        } else {
          endTop =
            windowHeight -
            toastRef.current.clientHeight -
            parseInt(theme.spacing.MD, 10);
        }
        controls.set({top: startTop, visibility: 'visible'});
        controls.start({
          top: endTop,
          transition: {duration: 0.15, ease: 'easeInOut'},
        });
        setTimeout(hideToast, onScreenTime);
      }
    }
  }, [displayedText]);

  return (
    <S.ToastWrapper animate={controls} ref={toastRef}>
      <S.Toast type={displayedType}>
        {displayedType === 'negative' && <S.Icon src={NegativeIcon} />}
        {displayedIcon && <S.Icon src={displayedIcon} />}
        <S.Text>{displayedText}</S.Text>
      </S.Toast>
    </S.ToastWrapper>
  );
};

export default withTheme(Toast);
