import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
// Components
import Toast from '@brightlive/shared/components/Toast';
// Redux
import {UIReducer} from 'redux/ui/reducer';
import {toggleToast} from 'redux/ui/actions';

const ToastWrapper = () => {
  const dispatch = useDispatch();

  const toast = useSelector((state: UIReducer) => state.ui.toast);
  const windowHeight = useSelector((state: UIReducer) => state.ui.windowHeight);

  const hideToast = () => {
    dispatch(toggleToast(false, '', '', '', toast.placement));
  };

  return (
    <Toast
      text={toast.text}
      icon={toast.icon}
      toggled={toast.toggled}
      type={toast.toastType}
      placement={toast.placement}
      hideToast={hideToast}
      windowHeight={windowHeight}
    />
  );
};

export default React.memo(ToastWrapper);
