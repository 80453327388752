import styled, {DefaultTheme} from 'styled-components';
import {media} from '../../../shared/styles/breakpoints';

const S: Record<string, React.ElementType> = {};

S.Modal = styled.div(
  (props: {mobileCentered: boolean; embed: boolean}) => `
  ${
    !props.embed
      ? `position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;`
      : 'position: relative'
  };
  display: flex;
  justify-content: center;
  z-index: 3000;
  align-items: ${props.mobileCentered ? 'center' : 'flex-end'};

  ${media.tablet} {
    align-items: center;
  }
`
);

S.BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 1;
`;

S.ModalWrapper = styled.div(
  (props: {
    mobileHeight: string;
    desktopHeight: string;
    desktopWidth: string;
    mobileCentered: boolean;
    theme: DefaultTheme;
  }) => `
    max-height: 100vh;
    overflow-y: auto;
    background: ${props.theme.backgroundColor.backgroundPrimary};
    position: relative;
    z-index: 2;
    width: ${props.mobileCentered ? 'calc(100vw - 30px)' : '100%'};
    height: ${props.mobileHeight || 'auto'};
    box-sizing: border-box;
    border-top-left-radius: ${props.mobileHeight === '100%' ? '0' : '15px'};
    border-top-right-radius: ${props.mobileHeight === '100%' ? '0' : '15px'};
    border-bottom-left-radius: ${props.mobileCentered ? '15px' : '0'};
    border-bottom-right-radius: ${props.mobileCentered ? '15px' : '0'};

    ${media.tablet} {
      max-height: 95vh;
      width: ${props.desktopWidth || '500px'};
      height: ${props.desktopHeight || 'auto'};
      min-width: ${props.desktopWidth || '500px'};
      border-radius: 10px;
    }
`
);

S.ModalWrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  position: relative;
`;

S.TopWrapper = styled.div`
  background: ${props => props.theme.backgroundColor.backgroundPrimary};
  position: relative;
  padding: ${props => props.theme.spacing.XS};
  display: flex;
  justify-content: space-between;
`;

S.IconPlaceholder = styled.div`
  height: 48px;
`;

S.ConfettiWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
`;

S.ContentWrapper = styled.div(
  (props: {overflowMobile: boolean; theme: DefaultTheme}) => `
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  flex: 1;
  position: relative;
  z-index: 100;
  padding: 0 ${props.overflowMobile ? '0' : props.theme.margin.mobile.page};
  overflow: ${props.overflowMobile ? 'visible' : 'hidden'};

  & > div:first-child {
    padding-bottom: 40px;
  }

  ${media.tablet} {
    padding: 0 40px;
    overflow: hidden;

    & > div:first-child {
      padding-bottom: 50px;
    }
  }
`
);

S.Buttons = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: ${props => props.theme.borderWidth[1]} solid
    ${props => props.theme.borderColor.borderSubdued};
  background: ${props => props.theme.backgroundColor.backgroundPrimary};

  ${media.desktop} {
    padding: 24px 40px;
  }
`;

S.CancelButton = styled.div`
  margin-right: 16px;
`;

export default S;
