import styled, {DefaultTheme} from 'styled-components';
import {motion} from 'framer-motion';
import {media} from '@brightlive/shared/styles/breakpoints';
import T from '@brightlive/shared/styles/typography';

const S: Record<string, React.ElementType> = {};

S.ToastWrapper = styled(motion.div)`
  position: fixed;
  top: 100vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20000;
  width: calc(100vw - (${props => props.theme.margin.mobile.page} * 2));
  display: flex;
  justify-content: center;

  ${media.desktop} {
    width: auto;
  }
`;

S.Toast = styled.div(
  (props: {type: string; theme: DefaultTheme}) => `
  padding: ${props.theme.spacing.SM} ${props.theme.spacing.MD};
  background: ${
    props.type === 'negative'
      ? props.theme.backgroundColor.backgroundNegative
      : props.theme.backgroundColor.backgroundInformational
  };
  box-shadow: ${props.theme.elevation.level3};
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: calc(100vw - (${props.theme.margin.mobile.page} * 2));

  ${media.desktop} {
    max-width: none;
  }
`
);

S.Icon = styled.img`
  margin-right: ${props => props.theme.spacing.XS};
  width: 24px;
`;

S.Text = styled(T.ParagraphMedium)(
  (props: {type: string; theme: DefaultTheme}) => `
&& {
  color: ${
    props.type === 'negative'
      ? props.theme.contentColor.contentInverse
      : props.theme.contentColor.contentWhite
  };
}

`
);

export default S;
